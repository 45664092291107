import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Header from './header.component';
import Search from './search.component';
import CurrentSnp from './current-snp.component';
import NavBar from './navbar.component';
import Passport from './passport.component';
import SnpInfo from './snpInfo.component';

import { Enter, Settings, LoginIcon } from '../../assets/icons';

import { LeftMenuContainer, Tab, TabContainer, TabContentContainer, LoginContainer } from './index.styles';
import { IMarker, IRegion, ISnp } from '../../interfaces/snp.interface';
import { login } from '../../requests/auth.request';
import { removeTokens, setTokens } from '../../requests/token.request';
import { getGeneralInfo } from '../../requests/snp.request';
import { getSettlementTree } from '../../requests/kato.request';
import i18n from 'i18next';
import { getLang } from '../../utils/helpers.utils';
import { useParams } from 'react-router-dom';
import Policy from '../../assets/files/policy.pdf'
import { checkToken } from '../../requests/handle.request';
import { useSharedContext } from '../../contexts/shared.context';
import keycloak from '../../keycloak/keycloak';

interface IProps {
  selectedLink: string | null;
  setSelectedLink: (link: string) => void;
  selectedRegion: IRegion;
  setSelectedRegion: (region: IRegion) => void;
  selectedMarker: IMarker;
  setSelectedMarker: (marker: IMarker) => void;
  markers: IMarker[];
  setZoomedRegion: (region: IRegion | null) => void;
}

const LeftMenu: React.FC<IProps> = ({ selectedLink, setSelectedLink, selectedRegion, setSelectedRegion, selectedMarker, setSelectedMarker, setZoomedRegion }) => {
  const { t } = useTranslation();
  const { language } = i18n;
  const navigate = useNavigate();
  const { kato, link, tab } = useParams()
  const [params] = useSearchParams();
  const { user, setUser, isModalOpen } = useSharedContext();

  const [chosenLanguage, setChosenLanguage] = useState<'RU' | 'KZ'>('KZ');
  const [searchValue, setSearchValue] = useState('');
  const [selectedTab, setSelectedTab] = useState<'passport' | 'info'>('passport');
  const [showLogin, setShowLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [passportInfo, setPassportInfo] = useState<any>(null);
  const [snpInfo, setSnpInfo] = useState<any>(null);
  const [snpList, setSnpList] = useState<{ label: string, value: ISnp }[]>([]);
  const [errorMsg, setErrorMsg] = useState('');

  const handleLanguageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChosenLanguage(e.target.checked ? 'RU' : 'KZ');
    localStorage.setItem('lng', e.target.checked ? 'ru' : 'kz');
    i18n.changeLanguage(e.target.checked ? 'ru' : 'kz');
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const latinicPattern = /[A-Za-z]/;
    if (latinicPattern.test(inputValue)) {
      return;
    }
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleSearch = () => {
    console.log('searching');
  };

  const handleSelectTab = (tab: 'passport' | 'info') => {
    setSelectedTab(tab);
  }

  const handleLogin = () => {
    keycloak.login()
    // login(username, password)
    //   .then(res => {
    //     if (res.success) {
    //       setTokens(res.access, res.refresh)
    //       localStorage.setItem('user', JSON.stringify(res.userInfo));
    //       setIsLoggedin(true);
    //       setUser(res.userInfo);
    //     }
    //   })
    //   .catch(err => {
    //     setErrorMsg(err.response.data.msg);
    //   });
  }

  const handleLogout = () => {
    keycloak.logout()
    // localStorage.removeItem('user');
    // setIsLoggedin(false);
    // setUser(null);
    // removeTokens();
  }

  const handleGoToAdminPanel = () => {
    checkToken()
      .then(res => {
        navigate('/admin/snp');
      })
      .catch(err => {
        handleLogout();
      })
  }

  const handleSelectOption = (option: { label: string, value: ISnp }) => {
    let url = `/${option.value.kato}`

    if (link) {
      url = `/${option.value.kato}/${link}`
    }

    if (tab && link === 'infrastructure') {
      url = `/${option.value.kato}/${link}/${tab}`
    }

    navigate(url);
  }

  const getSnpList = useCallback(() => {
    getSettlementTree(language.toUpperCase() as 'RU' | 'KZ').then(res => {
      const children = Object.values(res.data)
        .flatMap((item) => Object.values(item).flatMap(item => item))
        .map((item) => ({ label: item[`name${getLang()}`], value: item }))
      setSnpList(children)
    })
  }, [language])

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      setIsLoggedin(true);
      setUser(JSON.parse(userData));
    }

    const lang = localStorage.getItem('lng');
    if (lang) {
      i18n.changeLanguage(lang);
      setChosenLanguage(lang === 'ru' ? 'RU' : 'KZ');
      getSnpList();
    } else {
      i18n.changeLanguage('ru');
      setChosenLanguage('RU');
      getSnpList();
    }
  }, [getSnpList]);

  useEffect(() => {
    if (selectedMarker || kato) {
      const snpData = JSON.parse(localStorage.getItem('snp') as string);
      const val = kato ? kato : snpData;
      if (val && !isNaN(+val)) {
        getGeneralInfo(val).then(res => {
          if (res) {
            setPassportInfo([{ ...res.supervisor, type: 's' }, { ...res.police, type: 'p' }]);
            setSnpInfo(res);
          }
        })
      }
    }
  }, [selectedMarker, kato]);

  useEffect(() => {
    setErrorMsg('');
  }, [username, password]);

  useEffect(() => {
    getSnpList();
  }, [getSnpList, language])

  useEffect(() => {
    if (params && params.get('lang')) {
      i18n.changeLanguage(params.get('lang') as 'kz' | 'ru')
      localStorage.setItem('lng', params.get('lang') as 'kz' | 'ru');
      setChosenLanguage((params.get('lang') as 'kz' | 'ru').toUpperCase() as 'RU' | 'KZ');
    }
  }, [params])

  return (
    <LeftMenuContainer active={showLogin} isModalOpen={isModalOpen}>
      <Header
        chosenLanguage={chosenLanguage}
        onChange={handleLanguageChange}
        showLogin={showLogin}
        setShowLogin={setShowLogin}
      />
      {
        showLogin
          ? <LoginContainer>
            <div className="block">
              <div className="heading">
                <Settings />
                {t('settings')}
              </div>
            </div>

            {
              keycloak.authenticated
                ? <div className="block">
                  <div className="heading">
                    <LoginIcon />
                    <span className="username">
                      {/* {user.name || 'Username'} */}
                    </span>
                  </div>

                  <button onClick={handleGoToAdminPanel}>
                    {t('loginToAdminPanel')}
                    <Enter />
                  </button>
                  <button onClick={handleLogout}>
                    {t('logout')}
                    <Enter />
                  </button>
                </div>
                : <div className="block">
                  <button onClick={handleLogin}>
                    {t('login')}
                    <Enter />
                  </button>
                </div>
            }

            {<div className="add-info">
              <a href="https://adilet.zan.kz/rus/docs/V17N0007014" target='_blank' rel="noreferrer">{t('RegionImprovementRules')}</a>
            </div>}
            {<div className="add-info">
              <a href={Policy} target='_blank' rel="noreferrer">{t('policy')}</a>
            </div>}
          </LoginContainer>
          : <>
            {
              snpList && snpList.length > 0
              && <Search
                searchValue={searchValue}
                onChange={handleSearchChange}
                onClear={clearSearch}
                onSearch={handleSearch}
                options={snpList}
                onSelectOption={handleSelectOption}
              />
            }
            <CurrentSnp selectedSnp={selectedMarker} selectedRegion={selectedRegion} />

            <NavBar
              selectedLink={selectedLink}
              setSelectedLink={setSelectedLink}
              selectedRegion={selectedRegion}
            />
            <TabContainer>
              <Tab
                isSelected={selectedTab === 'passport'}
                onClick={() => handleSelectTab('passport')}>
                {t('akim')}
              </Tab>
              <Tab
                isSelected={selectedTab === 'info'}
                onClick={() => handleSelectTab('info')}>
                {t('info')}
              </Tab>

            </TabContainer>
            <TabContentContainer>
              {selectedTab === 'passport' ? <Passport people={passportInfo} /> : <SnpInfo info={snpInfo} />}
            </TabContentContainer>
          </>
      }

    </LeftMenuContainer>
  )
}

export default LeftMenu