import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Education from './education.component';
import Health from './health.component';
import CultureSport from './culture-sport.component';
import Analytics from './analytics.component';
import AccessRoad from './access-road.components';
import GasSupply from './gas-supply.component';
import Internet from './internet.component';
import Lighting from './lighting.component';
import VillageRoad from './village-road.component';
import WaterSupply from './water-supply.component';

import { navLinks, reportTypes, tabLinks } from '../../constants/navlinks.constant';
import { Heading, Info, NavBar, NavItem } from './index.styles';
import { IMarker, IRegion } from '../../interfaces/snp.interface';
import AgricultureProducers from './agriculture-producers.component';
import VillageImprovement from './village-improvement.component';
import Investment from './investment.component';
import Passport from './passport.component';
import SnpInfo from './snp-info.component';
import DistrictImprovement from './district-improvement.component';
import { getSettlementTree } from '../../requests/kato.request';
import { capitalize, getLang } from '../../utils/helpers.utils';
import FloodSituation from './flood-situation.component';
import { ArrowUp } from '../../assets/icons';

interface IProps {
  selectedTab: string | null;
  selectedLink: string | null;
  selectedMarker: IMarker | null;
  setSelectedTab: (tab: string) => void;
  selectedRegion: IRegion | undefined;
}

const Content: React.FC<IProps> = ({ selectedTab, selectedLink, selectedMarker, setSelectedTab, selectedRegion }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { kato, link, tab } = useParams();
  const contentRef = useRef<HTMLDivElement>(null);

  const [selectedReport, setSelectedReport] = useState<string>(reportTypes[0]);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [isDistrict, setIsDistrict] = useState<boolean>(false);
  const [district, setDistrict] = useState<any>(null);
  const [snpList, setSnpList] = useState<any>(null);

  const handleSelectReport = (report: string) => {
    setSelectedReport(report);
    !navLinks.map(item => item.name).includes(kato as any) ? navigate(`/${kato}/infrastructure/${report}`) : navigate(`/infrastructure/${report}`)
  }

  const getHeading = useCallback(() => {
    if (tabLinks.includes(tab as string)) {
      return t(`report_link_types.${tab}`)
    } else if (['info', 'analytics', 'akim'].includes(selectedTab as string)) {
      return selectedTab === 'analytics' ? t('snpAnalytics') : t(selectedTab as string)
    } else {
      return t(`navLinks.${selectedLink}`);
    }
  }, [t, selectedLink, tab, selectedTab])

  const loadReport = useCallback(() => {
    if (selectedReport) {
      switch (selectedReport) {
        case 'education':
          selectedMarker && setContent(<Education kato={selectedMarker.code} contentRef={contentRef} />)
          break;
        case 'health':
          selectedMarker && setContent(<Health kato={selectedMarker.code} contentRef={contentRef} />)
          break;
        case 'culture':
          selectedMarker && setContent(<CultureSport kato={selectedMarker.code} contentRef={contentRef} />)
          break;
        case 'driveway':
          selectedMarker && setContent(<AccessRoad kato={selectedMarker.code} contentRef={contentRef} />)
          break;
        case 'gas':
          selectedMarker && setContent(<GasSupply kato={selectedMarker.code} contentRef={contentRef} />)
          break;
        case 'internet':
          selectedMarker && setContent(<Internet kato={selectedMarker.code} contentRef={contentRef} />)
          break;
        case 'light':
          selectedMarker && setContent(<Lighting kato={selectedMarker.code} contentRef={contentRef} />)
          break;
        case 'countryroad':
          selectedMarker && setContent(<VillageRoad kato={selectedMarker.code} contentRef={contentRef} />)
          break;
        case 'water':
          selectedMarker && setContent(<WaterSupply kato={selectedMarker.code} contentRef={contentRef} />)
          break;
        default:
          selectedMarker && setContent(<Education kato={selectedMarker.code} contentRef={contentRef} />)
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport, selectedMarker])

  useEffect(() => {
    selectedLink === 'infrastructure' && loadReport()
    setIsDistrict(false)
  }, [selectedReport, selectedMarker, selectedLink, loadReport])

  const getContent = useCallback(() => {
    if (tabLinks.includes(selectedTab as string)) {
      if (isDistrict) {
        return <DistrictImprovement contentRef={contentRef} />
      } else {
        return content;
      }
    } else {
      switch (selectedTab) {
        case 'analytics':
          return <Analytics selectedMarker={selectedMarker} />;
        case 'akim':
          return <Passport selectedMarker={selectedMarker} />;
        case 'info':
          return <SnpInfo selectedMarker={selectedMarker} />;
        default:
          return null;
      }
    }
  }, [content, selectedMarker, selectedTab, isDistrict])

  const handleGoUp = () => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    setIsDistrict(false)
    switch (selectedLink) {
      case 'infrastructure':
        loadReport();
        break;
      case 'agriculture':
        selectedMarker && setContent(<AgricultureProducers kato={selectedMarker.code} contentRef={contentRef} />)
        break;
      case 'landscape':
        selectedMarker && setContent(<VillageImprovement kato={selectedMarker.code} contentRef={contentRef} />)
        break;
      case 'investment':
        selectedMarker && setContent(<Investment kato={selectedMarker.code} contentRef={contentRef} />)
        break;
      case 'flood_situation':
        selectedRegion && setContent(<FloodSituation region={selectedRegion} contentRef={contentRef} />)
        break;
      default:
    }
  }, [loadReport, selectedLink])

  useEffect(() => {
    if ((kato && ['akim', 'info', 'analytics'].includes(kato as any))) {
      setSelectedTab(kato)
    } else if ((link && ['akim', 'info', 'analytics'].includes(link as any))) {
      setSelectedTab(link)
    } else if (kato === 'infrastructure' || link === 'infrastructure') {
      if (kato && link && tab) {
        handleSelectReport(tab)
      } else if (kato && link && !tab) {
        handleSelectReport(link)
      }
    }

    if (kato) {
      if (snpList) {
        const found = snpList.find((item: any) => item.kato === +kato);
        if (found) {
          setDistrict(found)
        }
      } else {
        getSettlementTree()
          .then((res: any) => {
            const list = Object.values(res.data).map((item: any) => Object.values(item)).flatMap(item => item).flatMap(item => item)
            setSnpList(list)
            const found = list.find((item: any) => item.kato === +kato)
            if (found) {
              setDistrict(found)
            }
          })
      }
    }
  }, [kato, link, tab])

  return (
    <>
      <Heading>
        <span onClick={() => setIsDistrict(!isDistrict)}
          className={
            getHeading() === t('navLinks.landscape') && !isDistrict
              ? 'active'
              : getHeading() === t('navLinks.landscape') && isDistrict
                ? 'hasBorder'
                : ''}>
          {getHeading()}
        </span>
        {getHeading() === t('navLinks.landscape')
          && <>  <span
            className={isDistrict ? 'active' : 'hasBorder'}
            onClick={() => setIsDistrict(!isDistrict)}>
            {t('district-improvement')}
          </span>
            {' '}
            ({district?.[`pname${getLang()}`]})
          </>
        }
      </Heading>
      <Info>
        {tabLinks.includes(selectedTab as string) && selectedLink === 'infrastructure' &&
          <NavBar>
            {tabLinks.map((report) =>
              <NavItem key={report} selected={selectedReport === report} onClick={() => handleSelectReport(report)}>
                <p> {t(`report_link_types.${report}`)}</p>
              </NavItem>
            )}
          </NavBar>
        }
        <div className="content">
          {getContent()}
          <div className="go-up" onClick={handleGoUp}><ArrowUp /></div>
        </div>
      </Info>
    </>
  )
}

export default Content