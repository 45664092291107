import Icon1 from '../assets/icons/navlinks/navlink1.svg'
import Icon2 from '../assets/icons/navlinks/navlink2.svg'
import Icon3 from '../assets/icons/navlinks/navlink3.svg'
import Icon4 from '../assets/icons/navlinks/navlink4.svg'
import WaterDrop from '../assets/icons/water-drop.svg'
import AgricultureProducersForm from '../components/admin-page/agriculture-producers-form.component';
import FloodSituationForm from '../components/admin-page/flood-situation.form.component';
import DistrictImprovementForm from '../components/admin-page/district-improvement-form.component';
import InvestmentForm from '../components/admin-page/investment-form.component';
import VillageImprovementForm from '../components/admin-page/village-improvement-form.component';
import AccessRoadForm from '../components/insfrastructureProjects/access-road-form.component';
import CultureSportForm from '../components/insfrastructureProjects/culture-sport-form.component';
import EducationForm from '../components/insfrastructureProjects/education-form.component';
import GasSupplyForm from '../components/insfrastructureProjects/gas-supply-form.component';
import HealthForm from '../components/insfrastructureProjects/health-form.component';
import InternetForm from '../components/insfrastructureProjects/internet-form.component';
import LightingForm from '../components/insfrastructureProjects/lighting-form.component';
import VillageRoadForm from '../components/insfrastructureProjects/village-road-form.component';
import WaterSupplyForm from '../components/insfrastructureProjects/water-supply-form.component';

export interface IRouteConfig {
  path: string;
  component: JSX.Element | string;
}

export const navLinks: { [key: string]: string }[] = [
  {
    name: 'infrastructure',
    icon: Icon1,
  },
  // {
  //   name: 'agriculture',
  //   icon: Icon2,
  // },
  {
    name: 'landscape',
    icon: Icon3,
  },
  {
    name: 'investment',
    icon: Icon4,
  },
  {
    name: 'flood_situation',
    icon: WaterDrop,
  },
]

export const reportTypes: string[] = [
  'education',
  'health',
  'culture-sport',
  'water-supply',
  // 'gas-supply',
  'internet-communication',
  'access-road',
  'village-road',
  'lighting',
]

export const tabLinks = [
  'education',
  'health',
  'culture',
  'water',
  // 'gas',
  'internet',
  'driveway',
  'countryroad',
  'light',
]

export const sections: string[] = [
  'snp',
  'analytics',
  'sections',
]

export const tabs: string[] = [
  'general_info',
  'infrastructure',
  'agricultural_producers',
  'village_improvement',
  'investments',
  'additional_info'
]

export const headerOptions = ['snp', 'analytics', 'akim', 'info'];

export const reportTypesRouteConfig: IRouteConfig[] = [
  {
    path: 'education',
    component: <EducationForm />,
  },
  {
    path: 'health',
    component: <HealthForm />,
  },
  {
    path: 'culture-sport',
    component: <CultureSportForm />,
  },
  {
    path: 'water-supply',
    component: <WaterSupplyForm />,
  },
  {
    path: 'gas-supply',
    component: <GasSupplyForm />,
  },
  {
    path: 'internet-communication',
    component: <InternetForm />,
  },
  {
    path: 'access-road',
    component: <AccessRoadForm />,
  },
  {
    path: 'village-road',
    component: <VillageRoadForm />,
  },
  {
    path: 'lighting',
    component: <LightingForm />,
  },
]

export const formsRouteConfig: IRouteConfig[] = [
  {
    path: 'snp_agriculture_producers',
    component: <AgricultureProducersForm />,
  },
  {
    path: 'snp_improvement',
    component: <VillageImprovementForm />,
  },
  {
    path: 'snp_investment',
    component: <InvestmentForm />,
  },
  {
    path: 'district-improvement',
    component: <DistrictImprovementForm />,
  },
  {
    path: 'flood_situation',
    component: <FloodSituationForm />,
  },

]