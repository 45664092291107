import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { tabs } from '../../constants/navlinks.constant'

import { TabsContainer, Tab } from './admin-page.styles'

interface IProps {
  selectedTab: string,
  onSelect: (tab: string) => void
}

const Tabs: React.FC<IProps> = ({ selectedTab, onSelect }) => {
  const [tabList, setTabList] = useState<any>(tabs);

  useEffect(() => {
    const location = window.location.href
    setTabList(location.includes('district') 
    ? ["district_improvement"] 
    : location.includes('flood_situation') 
    ? ["flood_situation"] 
    : tabs)
  }, [])

  const { t } = useTranslation()

  return (
    <TabsContainer>
      {
        tabList.map((tab: string) =>
          <Tab key={tab} isSelected={selectedTab === tab} onClick={() => onSelect(tab)}>
            {t(`navLinks.${tab}`)}
          </Tab>)
      }
    </TabsContainer>
  )
}

export default Tabs