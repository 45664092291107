import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { transformList } from './education.component';

import { getProjectList } from '../../requests/snp.request';
import { EducationContainer, EducationItem } from './index.styles';
import 'react-toastify/dist/ReactToastify.css';
import ZoomableImageModal from '../zoomable-image.component';
import { IProject } from '../../interfaces/snp.interface';
import Documentation from '../reportComponents/documentation.component';
import AdditionalFields from '../reportComponents/additional-fields.component';
import ImageGrid from '../reportComponents/image-grid.component';
import { checkNonEmpty, checkVal, getLang } from '../../utils/helpers.utils';
import { ArrowDown } from '../../assets/icons';

const Health = ({ kato, contentRef }: { kato: number, contentRef: any }) => {
  const { t } = useTranslation();
  const [projectList, setProjectList] = useState<IProject[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImageList, setCurrentImageList] = useState<any[]>([]);
  const [isVisible, setIsVisible] = useState<Record<number, boolean>>({});
  const [objectName, setObjectName] = useState<string>('Фото');

  const openImageModal = (image: any, images: any, name: string) => {
    setIsOpen(true);
    setSelectedImage(image);
    const arr: any = (Object.values(images).reduce((acc: any, item: any) => [...acc, ...item], []));
    const imageList = (arr.reduce((acc: any, item: any) => [...acc, ...item.imageIds], []));
    setCurrentImageList(imageList);
    setObjectName(name);
  }


  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
    setCurrentImageList([]);
  }

  const handleOpenProject = (id: number) => {
    setIsVisible(prev => ({ ...prev, [id]: !prev[id] }));
  }

  // useEffect(() => {
  //   const snpData = JSON.parse(localStorage.getItem('snp') as string);
  //   const val = kato ? kato : snpData.kato
  //   if (val) {
  //     getProjectList('health', val)
  //       .then((res) => {
  //         const filtered = res.filter((project: any) => Object.keys(project).some((key) => !['id', 'kato'].includes(key) && checkVal(project[key]) && checkNonEmpty(project[key])));
  //         const obj = filtered.reduce((acc: any, item: any) => {
  //           acc[item.id] = res.length <= 3;
  //           return acc;
  //         }, {})
  //         setProjectList(transformList(filtered));
  //         setIsVisible(obj);
  //       })
  //       .catch((err) => {
  //         toast.error('Снп не выбрано');
  //       })
  //   }
  // }, [])

  useEffect(() => {
    getProjectList('health', kato)
      .then((res) => {
        const filtered = res.filter((project: any) => Object.keys(project).some((key) => !['id', 'kato'].includes(key) && checkVal(project[key]) && checkNonEmpty(project[key])));
        const obj = filtered.reduce((acc: any, item: any) => {
          acc[item.id] = res.length <= 3;
          return acc;
        }, {})
        setProjectList(transformList(filtered));
        setIsVisible(obj);
      })
      .catch((err) => {
        toast.error('Снп не выбрано');
      })
  }, [kato])

  if (projectList.length === 0) {
    return <p>{t('no-objects')}</p>
  }

  return (
    <>
      <EducationContainer ref={contentRef}>
        {projectList.length > 0 && projectList.map((project) =>
          <EducationItem key={project.id}>
            <div className="heading with-icon" onClick={() => handleOpenProject(project.id)}>
              {project[`name${getLang()}`] || `${t('object-name')} ${project.id}`}
              <ArrowDown width="12" style={{ transform: isVisible[project.id] ? 'rotate(180deg)' : 'rotate(270deg)' }} />
            </div>

            {isVisible[project.id] && <div className="body">
              {['constructionYear', 'power', 'factInfo', 'factCondition'].some((key) => checkVal(project[key]))
                && <div className='items'>
                  {
                    checkVal(project.constructionYear)
                    && <div className='item'>
                      <div>
                        {t('form.constructionYear')}:
                        <b>{project.constructionYear}</b>
                      </div>
                    </div>
                  }
                  {
                    checkVal(project.power)
                    && <div className='item'>
                      <div>
                        {t('form.project-power')}:
                        <b>{project.power}</b>
                        {t('form.visitsPerDay')}
                      </div>
                    </div>
                  }
                  {
                    checkVal(project.factInfo)
                    && <div className='item'>
                      <div>
                        {t('form.fact-power')}:
                        <b>{project.factInfo}</b>
                        {t('form.visitsPerDay')}
                      </div>
                    </div>
                  }
                  {
                    checkVal(project.factCondition)
                    && <div className='item'>
                      <div>
                        {t('form.factCondition')}:
                        <b>{t(`${project.factCondition.toLowerCase()}`)}</b>
                      </div>
                    </div>
                  }
                </div>}

              {(project[`factLocation${getLang()}`]) && <div className="block" style={{ padding: 0, border: 0 }}>
                {project[`factLocation${getLang()}`] &&
                  <div className="text">{t('form.factPlace')}: <b>{t(project[`factLocation${getLang()}`].toLowerCase())}</b></div>
                }
              </div>
              }

              {(project[`repairRequired`] === 'true' || project[`repairRequired`] === true) ?
                <div className='text-block'>
                  <Documentation project={project} showFunds={false} />
                </div>
                : <div className="text">{t(`requirementsOptions.NOT_NEEDED`)}</div>
              }

              {checkVal(project.additionalFields) && Object.keys(project.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key)).length > 0 && <AdditionalFields project={project} />}

              {project.images && <ImageGrid images={project.images} openImageModal={openImageModal} name={project[`name${getLang()}`] || `${t('object-name')} ${project.id}`} />}

            </div>
            }
          </EducationItem>
        )}
        <ToastContainer />
      </EducationContainer>
      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={currentImageList}
          objectName={objectName}
        />
      )}
    </>
  )
}

export default Health