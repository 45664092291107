import React from 'react';
import { navLinks } from '../../constants/navlinks.constant';
import { useTranslation } from 'react-i18next';

import { NavBarWrapper, NavItem, NavIcon } from './index.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { IRegion } from '../../interfaces/snp.interface';
import { useSharedContext } from '../../contexts/shared.context';

interface IProps {
  selectedLink: string | null;
  setSelectedLink: (link: string) => void;
  selectedRegion: IRegion;
}

const NavBar: React.FC<IProps> = ({ selectedLink, setSelectedLink, selectedRegion }) => {
  const { t } = useTranslation();
  const { kato, link, tab } = useParams<any>();
  const { setIsRightMenuOpen } = useSharedContext()
  const navigate = useNavigate()

  const handleLinkClick = (linkName: any) => {
    if (linkName !== 'flood_situation') {
      if (!kato || (kato && navLinks.map(item => item.name).includes(kato as any)) || isNaN(+kato)) {
        setIsRightMenuOpen(false)
        toast.error(t('choose-village'));
        return
      }
    } else {
      if (!selectedRegion) {
        toast.error(t('choose-region'));
        return
      }else {
        setIsRightMenuOpen(true)
      }
    }

    setSelectedLink(linkName)

    let url = `/${linkName}`

    if (kato && !navLinks.map(item => item.name).includes(kato as any) && !isNaN(+kato)) {
      url = `/${kato}/${linkName}`
    }

    if (linkName === 'infrastructure') {
      url += tab ? `/${tab}` : '/education'
    }

    if (linkName === 'flood_situation') {
      url = `/${linkName}`
    }

    navigate(url);
  }

  return (
    <NavBarWrapper>
      {navLinks.map((link) =>
        <NavItem
          key={link.name}
          onClick={() => handleLinkClick(link.name)}
          isSelected={selectedLink === link.name}>
          <NavIcon>
            <img src={link.icon} alt={link.name} />
          </NavIcon>
          <div className="text">{t(`navLinks.${link.name}`)}</div>
        </NavItem>)
      }

      <ToastContainer />
    </NavBarWrapper>
  )
}

export default NavBar