import { DiagramType, FileDateType, IImage, IProject, IRegion, ISettlementsObject, OfficialRoleType } from "../interfaces/snp.interface";
import { api } from "../pages";

export const updateForm = (formData: IProject) => {
  return api.post("/supervisor/snp/general/update", formData);
}

export const createObject = (type: string, kato: number): Promise<IProject> => {
  return api.get(`/supervisor/snp/infrastructure/create?kato=${kato}&type=${type.toUpperCase().replaceAll('-', '_')}`).then(res => res.data);
}

//USE
export const createInfrastructureStreet = (type: string, parentSnpId: number): Promise<any> => {
  return api.get(`/supervisor/snp/infrastructure/street/create?parentSnpId=${parentSnpId}&type=${type.toUpperCase().replaceAll('-', '_')}`).then(res => res.data);
}

export const updateObject = (type: string, formData: IProject) => {
  return api.post(`/supervisor/snp/infrastructure/update?type=${type.toUpperCase().replaceAll('-', '_')}`, formData);
}

//USE
export const updateInfrastructureStreet = (type: string, formData: any[]) => {
  return api.post(`/supervisor/snp/infrastructure/street/update?type=${type}`, formData);
}

export const deleteObjectWithType = (type: string, infrastructureId: number) => {
  return api.delete(`/supervisor/snp/infrastructure/${infrastructureId}?type=${type.toUpperCase().replaceAll('-', '_')}`);
}

export const deleteInfrastructureStreet = (type: string, infrastructureId: number) => {
  return api.delete(`/supervisor/snp/infrastructure/street/${infrastructureId}?type=${type.toUpperCase().replaceAll('-', '_')}`);
}

export const uploadInfrastructureImage = (kato: number, objectId: number, type: string, date: Date, fileType: FileDateType, imageFile: File) => {
  const formData = new FormData();
  formData.append('kato', kato.toString());
  formData.append('objectId', objectId.toString());
  formData.append('imageFile', imageFile);
  formData.append('fileType', fileType);
  formData.append('type', type.toUpperCase().replaceAll('-', '_'));

  return api.post(`/supervisor/snp/infrastructure/image/upload?date=${date.toDateString()}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const uploadStreetImage = (kato: number, objectId: number, type: string, date: Date, fileType: FileDateType, imageFile: File) => {
  const formData = new FormData();
  formData.append('kato', kato.toString());
  formData.append('objectId', objectId.toString());
  formData.append('imageFile', imageFile);
  formData.append('fileType', fileType);
  formData.append('type', type.toUpperCase().replaceAll('-', '_'));

  return api.post(`/supervisor/snp/infrastructure/street/image/upload?date=${date.toDateString()}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const uploadGeneralImage = (kato: number, officialRole: OfficialRoleType, imageFile: File) => {
  const formData = new FormData();
  formData.append('kato', kato.toString());
  formData.append('officialRole', officialRole.toString());
  formData.append('imageFile', imageFile);

  return api.post(`/supervisor/snp/general/image/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const deleteImage = (imageId: number) => {
  return api.delete(`/supervisor/snp/image/remove/${imageId}`);
}

export const updateAnalyticsObject = (type: DiagramType, data: any[]) => {
  const analyticsType = ['harvest', 'lifestock'].includes(type.toLowerCase()) ? `AGRICULTURE_${type.toUpperCase()}` : type.toUpperCase();

  return api.post(`/supervisor/snp/analytics/create-update?type=${analyticsType.replaceAll('-', '_')}`, data).then(res => res);
}

export const deleteAnalyticsObject = (type: DiagramType, id: number) => {
  const analyticsType = ['harvest', 'lifestock'].includes(type.toLowerCase()) ? `AGRICULTURE_${type.toUpperCase()}` : type.toUpperCase();
  return api.delete(`/supervisor/snp/analytics/${id}?type=${analyticsType}`);
}

export const getFilteredRegionList = (): Promise<{ list: IRegion[] }> => {
  return api.get(`/supervisor/snp/list`)
};

export const getFilteredTree = (language: 'RU' | 'KZ' = 'RU'): Promise<ISettlementsObject> => {
  return api.get(`/supervisor/snp/tree?language=${language}`);
}

export const deleteAll = (kato: number) => {
  return api.delete(`/supervisor/snp/delete?kato=${kato}`);
}

export const changeStatus = (kato: number, status: 'NOT_STARTED' | 'IN_PROCESS' | 'COMPLETED') => {
  return api.post(`/supervisor/snp/status?kato=${kato}&status=${status}`);
}

export const handleEditImage = ({ fileType, fileId, date }: IImage) => {
  const body = {
    imageFile: null
  }
  return api.post(`/supervisor/snp/image/update?date=${new Date(date).toDateString()}&fileType=${fileType}&fileId=${fileId}`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}