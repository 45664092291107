import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  position: relative;
  width: 37rem;
  z-index: 4;
  background-color: white;
  padding: 0.625rem;
  border-radius: 5px;
  border: 1px solid red;
  div, a {
    font-size: 1.25rem;
  }

  button {
    position: absolute;
    top: 0;
    right: 0.5rem;
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;

const TestVersion = () => {
  const { i18n: { language } } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(true);

  const content = language === 'ru'
    ? <div>Внимание: вы находитесь на тестовой версии сайта. Просьба перейти на рабочую версию <a href="https://abat-kost.kz">abat-kost.kz</a></div>
    : <div>Назар аударыңыз: сіз сайттың сынақ нұсқасындасыз. Сізден жұмыс нұсқасына өтуіңізді сұраймыз <a href="https://abat-kost.kz">abat-kost.kz</a></div>


  return (<>
    {isOpen && <Wrapper>{content} <button onClick={() => setIsOpen(false)}>x</button></Wrapper>}
  </>
  )
}

export default TestVersion