import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { getProjectList } from '../../requests/snp.request';
import { AgricultureWrapper, EducationContainer, EducationItem } from './index.styles';
import AdditionalFields from '../reportComponents/additional-fields.component';
import ImageGrid from '../reportComponents/image-grid.component';
import { checkNonEmpty, checkVal, formatDate, getLang } from '../../utils/helpers.utils';
import { ArrowDown } from '../../assets/icons';
import { IRegion } from '../../interfaces/snp.interface';
import { getChildren } from '../../requests/kato.request';
import DoubleZoomableImageModal from '../double-zoomable-image.component';

const FloodSituation = ({ region, contentRef }: { region: IRegion, contentRef: any }) => {

  const { t } = useTranslation();

  const [projectList, setProjectList] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [objectName, setObjectName] = useState<string>('Фото')
  const [districtList, setDistrictList] = useState<any[]>([]);
  const [snpList, setSnpList] = useState<any[]>([]);

  const [isAllOpen, setIsAllOpen] = useState<boolean>(false);
  const [isListOpen, setIsListOpen] = useState<{ [key: string]: boolean }>({});
  const [imagesObj, setImagesObj] = useState<any>({});

  const handleOpen = (id: any) => {
    setIsListOpen(prev => ({ ...prev, [id]: !prev[id] }));
  }

  const handleOpenAll = () => {
    const obj = Object.keys(isListOpen).reduce((acc: any, key: any) => {
      acc[key] = !isAllOpen;
      return acc;
    }, {})

    setIsListOpen(obj);
  }

  useEffect(() => {
    const obj = projectList.reduce((acc: any, street: any) => {
      acc[street.id] = projectList.length < 3;
      return acc;
    }, {})
    setIsListOpen(obj);

    setIsAllOpen(Object.values(obj).every((value: any) => value === true));
  }, [projectList])

  useEffect(() => {
    setIsAllOpen(Object.values(isListOpen).every((value: any) => value === true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(isListOpen)])

  const openImageModal = (image: any, images: any, name: string) => {
    setIsOpen(true);
    setImagesObj(images);
    const initialImage = { ...image, type: images.before.map((item: any) => item.id).includes(image.imageId) ? 'before' : 'after' }
    setSelectedImage(initialImage);
    setObjectName(name);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  }

  useEffect(() => {
    const val = region?.kato;
    if (val) {
      getProjectList('FLOOD_SITUATION', val)
        .then((res) => {
          const arr = res.reduce((acc: any, item: any) => {
            return [
              ...acc,
              {
                ...item,
                imageArrs: {
                  before: item.files.filter((image: any) => image.fileType === 'IMAGE_CURRENT'),
                  after: item.files.filter((image: any) => image.fileType === 'IMAGE_AFTER')
                }
              }
            ]
          }, [])
          setProjectList(arr);
        })
        .catch((err) => {
          toast.error('Снп не выбрано');
        })
      getChildren(val).then(res => {
        setDistrictList(res);
        res.forEach((item: any) => {
          getChildren(item.kato).then(response => {
            setSnpList((prev: any) => [...prev, ...response]);
          })
        })
      })
    }

  }, [region])


  if (projectList.length === 0) {
    return <p>{t('no-objects')} </p>
  }
  return (
    <AgricultureWrapper>
      <div className='list-heading with-icon' onClick={handleOpenAll}>
        {t('event-list')}
        <ArrowDown width="12" style={{ transform: isAllOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
      </div>

      <EducationContainer ref={contentRef}>
        {projectList.length > 0 && projectList.map((project) =>
          <EducationItem key={project.id}>
            <div className="heading with-icon" onClick={() => handleOpen(project.id)}>
              <ArrowDown width="12" style={{ transform: isAllOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
              {project[`name${getLang()}`] || `${t('object-name')} ${project.id}`}
            </div>

            {isListOpen[project.id] && <div className='body'>
              {checkVal(project['districtKato']) && <div> {t(`form.district-name`)}: <b>{districtList.find(item => +item.kato === +project[`districtKato`])?.[`name${getLang()}`]}</b> </div>}
              {checkVal(project['snpKato']) && <div> {t(`form.snp.name`)}: <b>{snpList.find(item => +item.kato === +project[`snpKato`])?.[`name${getLang()}`]}</b> </div>}
              {checkVal(project['eventType']) && <div> {t(`district-improvement-form.eventType`)}: <b>{t(`eventType.${project['eventType']}`)}</b> </div>}
              {checkVal(project['workStart']) && checkVal(project['workEnd']) && <div> {t(`district-improvement-form.dates`)}: <b>{formatDate(project['workStart'])} - {formatDate(project['workEnd'])}</b> </div>}
              {checkVal(project.additionalFields) && Object.keys(project.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key)).length > 0 && <AdditionalFields project={project} />}
              {project.imageArrs &&
                <ImageGrid
                  images={project.imageArrs}
                  openImageModal={openImageModal}
                  name={project[`name${getLang()}`] || `${t('object-name')} ${project.id}`}
                  isColumn
                />
              }
            </div>
            }

          </EducationItem>
        )}
        <ToastContainer />
      </EducationContainer>
      {selectedImage && (
        <DoubleZoomableImageModal
          isOpen={isOpen}
          onClose={closeImageModal}
          objectName={objectName}
          images={imagesObj}
          initial={selectedImage}
        />
      )}
    </AgricultureWrapper>

  )
}

export default FloodSituation