export enum IconTypes {
  DEFAULT = 'DEFAULT',
  SUPPORT = 'SUPPORT',
  FLOOD = 'FLOOD',
}
interface IRegion {
  nameRu: string,
  nameKz: string,
  NAME_EN: string,
  coordinates: [number, number],
  kato: number,
}

interface ISnp {
  nameRu: string,
  nameKz: string,
  kato: number,
  ppNameKz?: string,
  ppNameRu?: string,
  pnameRu?: string,
  pnameKz?: string,
  pkato?: number,
  ppKato?: number,
  code?: number,
  active?: boolean,
  fillingStatus?: 'COMPLETED' | 'IN_PROCESS' | 'NOT_STARTED',
  icon?: IconTypes
}

interface ISettlement {
  name: string,
  children: any[],
  total: number,
  required: number,
  inProcess: number,
  completed: number,
  completionPercent: number
  kato: number
}

interface IMarker {
  code: number;
  latitude: string;
  longitude: string;
  nameKz: string;
  nameRu: string;
  type?: string;
  active?: boolean;
  fillingStatus?: 'COMPLETED' | 'IN_PROCESS' | 'NOT_STARTED',
  requiredFilling?: boolean,
  icon?: IconTypes
}

interface ISettlementsObject {
  [districtName: string]: {
    [settlementName: string]: ISnp[];
  },

}

type IPeople = IPerson[];

interface IPerson {
  fullNameRu: string | null,
  fullNameKz: string | null,
  inPositionFrom: string | null,
  phoneNumber: string | null,
  photoId: number | null,
  positionKz: OfficialRoleType | null,
  positionRu: OfficialRoleType | null,
  type?: 's' | 'p'
}

type OfficialRoleType = "SUPERVISOR" | "POLICE";
type QualityType = 'GOOD' | 'SATISFACTORY' | 'BAD'
type CoverType = 'PAVED' | 'GRAVEL' | 'GROUND'

interface IGeneralInfo {
  kato: number;
  buildingNumber: number | null;
  supervisor: IPerson,
  police: IPerson,
  areaRoad: {
    cover: CoverType | null;
    distance: number | null;
    quality: QualityType | null;
  },
  regionRoad: {
    cover: CoverType | null;
    distance: number | null;
    quality: QualityType | null;
  },
  employment: {
    acpReceivers: number | null;
    employed: number | null;
    notEmployed: number | null;
    selfEmployed: number | null;
  },
  population: {
    all: number | null;
    kazakhs: number | null;
    others: number | null;
    russians: number | null;
  },
  status?: 'COMPLETED' | 'IN_PROCESS' | 'NOT_STARTED';
  wonElection?: boolean;
}

interface IProject {
  constructionYear: number | null;
  documentationStatus: 'HAVE' | 'NOT_HAVE' | 'IN_PROCESS' | null | "";
  factInfo: number | null;
  id: number;
  images: any[] | null;
  kato: number;
  power: number | null;
  quality: string | null;
  requiredFund: any;
  requirements: string | null;
  sportSection: {
    [key: string]: string | null;
  }
  nameKz: string | null;
  nameRu: string | null;
  workDuration: string | null;
  [key: string]: any;
  lastMajorRenovation?: string | null;
}

interface OptionType {
  value: number;
  label: string;
  labelKz: string;
}

type DiagramType = 'studying' | 'road' | 'population' | 'nationality' | 'lifestock' | 'harvest' | 'finance' | 'employment' | 'education' | 'healthcare';

type FileDateType = 'IMAGE_AFTER' | 'IMAGE_CURRENT' | 'GENERAL_IMAGE'

interface IImage {
  fileId: number,
  fileType: FileDateType,
  date: Date | string,
}

export type {
  IRegion,
  ISnp,
  ISettlement,
  IMarker,
  ISettlementsObject,
  OfficialRoleType,
  IGeneralInfo,
  IProject,
  IPeople,
  IPerson,
  OptionType,
  DiagramType,
  FileDateType,
  QualityType,
  CoverType,
  IImage
}


