import React, { useEffect, useState } from 'react'
import { Expand, ImageArrowLeft, ImageArrowRight, ImageDownload } from '../assets/icons';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ZoomableImageContainer } from './right-menu/index.styles';
import Modal from './modal-component';
import { getImageUrl } from './right-menu/education.component';
import { Text } from './text.component';
import { useTranslation } from 'react-i18next';
import { useSharedContext } from '../contexts/shared.context';
import { formatDate } from '../utils/helpers.utils';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  objectName?: string,
  images?: {
    before: number[],
    after: number[]
  } | null,
  initial: any
}

enum ColumnType {
  BEFORE = 'before',
  AFTER = 'after'
}

const DoubleZoomableImageModal: React.FC<IProps> = ({ isOpen, onClose, objectName = 'Фото', initial, images }) => {
  const { t } = useTranslation()
  const { setIsModalOpen } = useSharedContext()

  const [indexes, setIndexes] = useState<any>({
    before: 0,
    after: 0
  })
  const [selectedImages, setSelectedImages] = useState<any>({
    before: null,
    after: null
  })

  const handleClose = () => {
    onClose();
    setIsModalOpen(false);
  }

  const handleNextImage = (type: ColumnType) => {
    if (images) {
      if (type === ColumnType.BEFORE) {
        if (indexes.before < images.before.length - 1) {
          const current = indexes.before
          setIndexes((prev: any) => ({ ...prev, before: current + 1 }))
          setSelectedImages((prev: any) => ({ ...prev, before: images.before[current + 1] }))
        }
      } else {
        if (indexes.after < images.after.length - 1) {
          const current = indexes.after
          setIndexes((prev: any) => ({ ...prev, after: current + 1 }))
          setSelectedImages((prev: any) => ({ ...prev, after: images.after[current + 1] }))
        }
      }
    }
  }

  const handlePrevImage = (type: ColumnType) => {
    if (images) {
      if (type === ColumnType.BEFORE) {
        if (indexes.before > 0) {
          const current = indexes.before
          setIndexes((prev: any) => ({ ...prev, before: current - 1 }))
          setSelectedImages((prev: any) => ({ ...prev, before: images.before[current - 1] }))
        }
      } else {
        if (indexes.after > 0) {
          const current = indexes.after
          setIndexes((prev: any) => ({ ...prev, after: current - 1 }))
          setSelectedImages((prev: any) => ({ ...prev, after: images.after[current - 1] }))
        }
      }
    }
  }

  // const handleArrowClick = (type: ColumnType) => {
  //   if (images) {
  //     if (type === ColumnType.BEFORE) {
  //       if (indexes.before > 0 && indexes.after > 0) {
  //         const cur = indexes
  //         setIndexes({ before: cur.before - 1, after: cur.after - 1 })
  //         setSelectedImages({ before: images.before[cur.before - 1], after: images.after[cur.after - 1] })
  //       }
  //     } else {
  //       if (indexes.before < images.before.length - 1 && indexes.after < images.after.length - 1) {
  //         const cur = indexes
  //         setIndexes({ before: cur.before + 1, after: cur.after + 1 })
  //         setSelectedImages({ before: images.before[cur.before + 1], after: images.after[cur.after + 1] })
  //       }
  //     }
  //   }
  // }

  const handleArrowClick = (type: ColumnType) => {
    if (images) {
      setIndexes((cur: any) => {
        if (type === ColumnType.BEFORE && cur.before > 0 && cur.after > 0) {
          const newIndexes = { before: cur.before - 1, after: cur.after - 1 };
          setSelectedImages({
            before: images.before[newIndexes.before],
            after: images.after[newIndexes.after]
          });
          return newIndexes;
        } else if (type === ColumnType.AFTER && cur.before < images.before.length - 1 && cur.after < images.after.length - 1) {
          const newIndexes = { before: cur.before + 1, after: cur.after + 1 };
          setSelectedImages({
            before: images.before[newIndexes.before],
            after: images.after[newIndexes.after]
          });
          return newIndexes;
        }
        return cur; // Return the current state if no changes are made.
      });
    }
  };


  const handleDownload = (id: number) => {
    const link = document.createElement('a');
    link.href = getImageUrl(id);
    link.setAttribute('download', `${objectName}-${indexes.before}.png`);
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  useEffect(() => {
    if (initial && images) {
      if (initial.type === ColumnType.BEFORE) {
        const found = images.before.find((item: any) => item.id === initial.imageId)
        if (found) {
          setIndexes({ after: 0, before: images.before.indexOf(found) })
          setSelectedImages({ after: images.after[0], before: found })
        }
      } else {
        const found = images.after.find((item: any) => item.id === initial.imageId);
        if (found) {
          setIndexes(({ before: 0, after: images.after.indexOf(found) }))
          setSelectedImages(({ before: images.before[0], after: found }))
        }
      }
    }

    const handleKeyDown = (e: any) => {
      if (e.code === 'ArrowRight') {
        handleArrowClick(ColumnType.AFTER);
      }
      if (e.code === 'ArrowLeft') {
        handleArrowClick(ColumnType.BEFORE);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [])

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen])


  if (!images || !initial) {
    return <></>
  }

  return (
    <Modal isFileUpload={false} isOpen={isOpen} onClose={handleClose} hasBg={false} isCompare>
      {
        images.before && selectedImages.before && <ZoomableImageContainer>
          <ImageArrowLeft className={'arrow prev down'} onClick={() => handlePrevImage(ColumnType.BEFORE)} />
          <div className="modal-body">
            <TransformWrapper
              initialScale={1}
              initialPositionX={0}
              initialPositionY={0}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <div className="tools">
                    <button className='zoom' onClick={() => zoomIn()}>+</button>
                    <button className='zoom' onClick={() => zoomOut()}>-</button>
                    <button className='zoom' onClick={() => resetTransform()}><Expand /> </button>
                  </div>
                  {selectedImages && <TransformComponent>
                    <img src={getImageUrl(+selectedImages.before.id)} alt="object" />
                  </TransformComponent>}
                </>
              )}
            </TransformWrapper>
            <div className="footer">
              <Text fontSize='0.875rem' color="#BFBFBF">{formatDate(selectedImages?.before?.date)}</Text>
              <div className='object-info'>
                <Text fontSize='0.875rem' color="#BFBFBF">{objectName}</Text>
                {images.before.length > 0 && <Text fontSize='0.875rem' color="#BFBFBF" style={{ whiteSpace: 'nowrap' }} >{indexes?.before + 1} / {images?.before?.length}</Text>}
              </div>
              <div className='download' onClick={() => handleDownload(selectedImages.before.id)}>
                <ImageDownload />
                <Text color='#92D050' fontSize='0.875rem' style={{ textDecoration: 'underline' }}>{t('download')}</Text>
              </div>
            </div>
          </div>
          <ImageArrowRight className={'arrow next down'} onClick={() => handleNextImage(ColumnType.BEFORE)} />
        </ZoomableImageContainer>
      }
      {
        images.after && selectedImages.after && <ZoomableImageContainer>
          <ImageArrowLeft className={'arrow prev down'} onClick={() => handlePrevImage(ColumnType.AFTER)} />
          <div className="modal-body">
            <TransformWrapper
              initialScale={1}
              initialPositionX={0}
              initialPositionY={0}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <div className="tools">
                    <button className='zoom' onClick={() => zoomIn()}>+</button >
                    <button className='zoom' onClick={() => zoomOut()}>-</button>
                    <button className='zoom' onClick={() => resetTransform()}><Expand /> </button>
                  </div>
                  {selectedImages && <TransformComponent>
                    <img src={getImageUrl(+selectedImages.after.id)} alt="object" />
                  </TransformComponent>}
                </>
              )}
            </TransformWrapper>
            <div className="footer">
              <Text fontSize='0.875rem' color="#BFBFBF">{formatDate(selectedImages?.after?.date)}</Text>
              <div className='object-info'>
                <Text fontSize='0.875rem' color="#BFBFBF">{objectName}</Text>
                {images?.after?.length > 0 && <Text fontSize='0.875rem' color="#BFBFBF" style={{ whiteSpace: 'nowrap' }} >{indexes.after + 1} / {images.after.length}</Text>}
              </div>
              <div className='download' onClick={() => handleDownload(selectedImages.after.id)}>
                <ImageDownload />
                <Text color='#92D050' fontSize='0.875rem' style={{ textDecoration: 'underline' }}>{t('download')}</Text>
              </div>
            </div>
          </div>
          <ImageArrowRight className={'arrow next down'} onClick={() => handleNextImage(ColumnType.AFTER)} />
        </ZoomableImageContainer>
      }
    </Modal>
  )
}

export default DoubleZoomableImageModal