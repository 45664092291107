import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { checkVal, getLang, lowerAndTrim } from '../../utils/helpers.utils';
import { IProject } from '../../interfaces/snp.interface';
import { ToastContainer, toast } from 'react-toastify';
import { createObject, updateObject } from '../../requests/supervisor.request';
import { getProject } from '../../requests/snp.request';
import { FooterButton, FormGroup, Wrapper } from './admin-page.styles';
import { Form, Field, Formik } from 'formik';
import FormFooter from '../insfrastructureProjects/footer.component';
import ImageGrid from '../insfrastructureProjects/image-grid.component';
import Modal from '../modal-component';
import { getChildren } from '../../requests/kato.request';
import { t } from '../../utils/helpers.utils'
import { dates, eventTypes, fundSourceTypes, realizationTypes } from '../../constants/district.constant';

const initialFormData = {
  totalSum: 0
}

const skipList = [
  'id',
  'kato',
  'imageCode',
  'files'
]

const errMsg: any = {
  nameRu: 'eventName',
  nameKz: 'eventName',
  workStart: 'deadlines',
  workEnd: 'deadlines',
  fundSourceNameRu: 'fundSourceName',
  fundSourceNameKz: 'fundSourceName',

}

const DistrictImprovementForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();
  const wrapperRef = useRef<any>(null);

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [showSnpModal, setShowSnpModal] = useState<any>(false);
  const [snpList, setSnpList] = useState<any[]>([]);
  const [selectedSnp, setSelectedSnp] = useState<any>({});
  const [snpNames, setSnpNames] = useState<any>({
    nameRu: null,
    nameKz: null
  });

  const handleSave = (values: IProject) => {
    setErrors({})

    const keys = Object.keys(values).filter((key: string) => !skipList.includes(key));
    for (const key of keys) {
      if (!checkVal(values[key])) {
        setErrors((prev: any) => ({ ...prev, [errMsg[key] || key]: true }))
        toast.error(t(`errors.${errMsg[key] || key}`, language))
        return
      }
    }

    if (dates.indexOf(values.workStart) > dates.indexOf(values.workEnd)) {
      setErrors((prev: any) => ({ ...prev, deadlines: true }))
      toast.error(t(`errors.deadlines`, language))
      return
    }

    let data: any = {
      ...values,
    }

    updateObject('DISTRICT_IMPROVEMENT', data)
      .then(res => toast.success(t(`toast.save_success`, language)))
      .catch(err => toast.error(t(`toast.save_error`, language)))
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('DISTRICT_IMPROVEMENT', objectId).then(res => {
      setFormData(res.infrastructureEntity)
      setImages(res.images)
      kato && getChildren(+kato).then(response => {
        const found = response.find((item: any) => item.kato === res.infrastructureEntity.kato)
        if (found) {
          setSnpNames({
            nameRu: found.nameRu,
            nameKz: found.nameKz
          })
        }
      })
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId);

    } else {
      getChildren(val).then(res => {
        setSnpList(res)
        setShowSnpModal(true)
      })
    }
  }, [navigate, objectId, kato])


  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  const handleCreateObject = () => {
    createObject('DISTRICT_IMPROVEMENT', selectedSnp).then(res => {
      navigate(`/admin/${kato}/district-improvement/${res.id}`)
      setShowSnpModal(false)
    })
  }

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormGroup>
        <label htmlFor={`snpName${lang}`}>{t(`form.snp.name`, lang)}</label>
        <Field
          id={`snpName${lang}`}
          name={`snpName${lang}`}
          placeholder={t(`snp_improvement.name`, lang)}
          value={snpNames[`name${lang}`]}
          disabled
        />
      </FormGroup>
      <FormGroup>
        <label className='required' htmlFor={`name${lang}`}>{t(`district-improvement-form.name`, lang)}</label>
        <Field
          id={`name${lang}`}
          name={`name${lang}`}
          placeholder={t(`district-improvement-form.name`, lang)}
          onChange={(e: any) => setFieldValue(`name${lang}`, lowerAndTrim(e.target.value))}
          className={errors['eventName'] ? 'error' : ''}
          as='textarea'
          rows={3}
        />
      </FormGroup>
      <FormGroup>
        <label className='required' htmlFor={`eventType`}>{t(`district-improvement-form.eventType`, lang)}</label>
        <Field
          id={`eventType`}
          name={`eventType`}
          placeholder={t(`district-improvement-form.eventType`, lang)}
          onChange={(e: any) => setFieldValue(`eventType`, e.target.value)}
          className={errors['eventType'] ? 'error' : ''}
          as='select'
          defaultValue={formData.eventType || ""}
        >
          <option value="" hidden></option>
          {eventTypes.map((item) => <option key={item} value={item}>{t(`eventType.${item}`, lang)}</option>)}
        </Field>
      </FormGroup>

      <FormGroup>
        <label className='required' htmlFor={`realizationType`}>{t(`district-improvement-form.realizationType`, lang)}</label>
        <Field
          id={`realizationType`}
          name={`realizationType`}
          placeholder={t(`district-improvement-form.realizationType`, lang)}
          onChange={(e: any) => setFieldValue(`realizationType`, e.target.value)}
          className={errors['realizationType'] ? 'error' : ''}
          as='select'
          defaultValue={formData.realizationType || ""}
        >
          <option value="" hidden></option>
          {realizationTypes.map((item) => <option key={item} value={item}>{t(`district-improvement-form.${item}`, lang)}</option>)}
        </Field>
      </FormGroup>

      <FormGroup>
        <label className='required' htmlFor={`fundSourceType`}>{t(`district-improvement-form.fundSource`, lang)}</label>
        <Field
          id={`fundSourceType`}
          name={`fundSourceType`}
          placeholder={t(`district-improvement-form.fundSource`, lang)}
          onChange={(e: any) => setFieldValue(`fundSourceType`, e.target.value)}
          className={errors['fundSourceType'] ? 'error' : ''}
          as='select'
          defaultValue={formData.fundSourceType || ""}
        >
          <option value="" hidden></option>
          {fundSourceTypes.map((item) => <option key={item} value={item}>{t(`district-improvement-form.${item}`, lang)}</option>)}
        </Field>

        <Field
          id={`fundSourceName${lang}`}
          name={`fundSourceName${lang}`}
          placeholder={t(`district-improvement-form.fundSourceName`, lang)}
          onChange={(e: any) => setFieldValue(`fundSourceName${lang}`, lowerAndTrim(e.target.value))}
          className={errors['fundSourceName'] ? 'error' : ''}
        />
      </FormGroup>

      <FormGroup>
        <label className='required' htmlFor={`name${lang}`}>{t(`district-improvement-form.fundSourceAmount`, lang)}</label>
        <Field
          id={`fundSourceAmount`}
          name={`fundSourceAmount`}
          placeholder={t(`district-improvement-form.fundSourceAmount`, lang)}
          onChange={(e: any) => setFieldValue(`fundSourceAmount`, lowerAndTrim(e.target.value))}
          className={errors['fundSourceAmount'] ? 'error' : ''}
          type='number'
          as='input'
          step={0.001}
          min={0}
          max={1000}
        />
      </FormGroup>

      <FormGroup>
        <label className='required' htmlFor={`workStart`}>{t(`form.workStart`, lang)}</label>
        <Field
          id={`workStart`}
          name={`workStart`}
          placeholder={t(`form.workStart`, lang)}
          onChange={(e: any) => {
            setFieldValue(`workStart`, e.target.value)
            setFormData({ ...formData, workStart: e.target.value })
          }}
          className={errors['deadlines'] ? 'error' : ''}
          as='select'
          defaultValue={formData.workStart || ""}
        >
          <option value="" hidden></option>
          {dates.map((item) => <option key={item} value={item}>{t(`${item}`, lang) || item}</option>)}
        </Field>
      </FormGroup>

      <FormGroup>
        <label className='required' htmlFor={`workEnd`}>{t(`form.workEnd`, lang)}</label>
        <Field
          id={`workEnd`}
          name={`workEnd`}
          placeholder={t(`form.workEnd`, lang)}
          onChange={(e: any) => {
            setFieldValue(`workEnd`, e.target.value)
            setFormData({ ...formData, workEnd: e.target.value })
          }}
          className={errors['deadlines'] ? 'error' : ''}
          as='select'
          defaultValue={formData.workEnd || ""}
        >
          <option value="" hidden></option>
          {dates.filter(item => isNaN(+item) === isNaN(+formData.workStart)).map((item) => <option key={item} value={item}>{t(`${item}`, lang) || item}</option>)}
        </Field>
      </FormGroup>
    </>
  }

  return (
    <Wrapper ref={wrapperRef}>
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="grid-item">
                  {renderFields(getLang(), setFieldValue)}
                </div>
                <div className="grid-item">
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                </div>
                <FormFooter type='DISTRICT_IMPROVEMENT' formId={formData.id} parent={wrapperRef?.current} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />

      {objectId !== 'new' && <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'DISTRICT_IMPROVEMENT'}
        images={images}
        lang={language}
      />}

      <Modal isOpen={showSnpModal} onClose={() => setShowSnpModal(false)}>
        <select name="" id="" defaultValue={""} value={selectedSnp} onChange={(e) => setSelectedSnp(e.target.value)}>
          <option value="" hidden></option>
          {snpList.map((item) => <option value={item.kato}>{item[`name${getLang()}`]}</option>)}
        </select>

        <FooterButton onClick={handleCreateObject} type='button' variant='save'>
          {t('save')}
        </FooterButton>
      </Modal>
    </Wrapper >
  )
}

export default DistrictImprovementForm