import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { reportTypes } from '../../constants/navlinks.constant';
import { InfrastructureTab, InfrastructureTabs } from './infrastructure.styles';

const Infrastructure = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedReport, setSelectedReport] = useState<any>(null)

  useEffect(() => {
    navigate(selectedReport)
  }, [selectedReport, navigate, id])

  useEffect(() => {
    const selected = window.location.pathname.split('/').pop();
    selected && reportTypes.includes(selected) && setSelectedReport(selected);
  }, [])

  return (
    <div>
      <InfrastructureTabs>
        {
          reportTypes.map(report =>
            <InfrastructureTab
              key={report}
              selected={selectedReport === report}
              onClick={() => setSelectedReport(report)}>{t(`report_types.${report}`)}</InfrastructureTab>)
        }
      </InfrastructureTabs>
      <Outlet />
    </div>
  )
}

export default Infrastructure