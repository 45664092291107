import { DiagramType } from "../interfaces/snp.interface"

const kostanayDistricts: {
  kato: number,
  coordinates: [number, number],
  NAME_EN: string
}[] = [
    { kato: 393200000, coordinates: [64.5, 53.0], NAME_EN: 'Altynsarin' },
    { kato: 393400000, coordinates: [65.2, 50.4], NAME_EN: 'Amangeldi' },
    { kato: 393600000, coordinates: [64.0, 52.2], NAME_EN: 'Auliekol' },
    { kato: 396400000, coordinates: [62.7, 52.7], NAME_EN: 'Taranov' },
    { kato: 394000000, coordinates: [61.6, 52.6], NAME_EN: 'Denisov' },
    { kato: 394200000, coordinates: [63.6, 49.7], NAME_EN: 'Zhangeldi' },
    { kato: 394400000, coordinates: [60.9, 51.9], NAME_EN: 'Zhitikara' },
    { kato: 394800000, coordinates: [62.3, 51.6], NAME_EN: 'Kamysty' },
    { kato: 395000000, coordinates: [61.9, 53.4], NAME_EN: 'Karabalyk' },
    { kato: 395200000, coordinates: [65.4, 52.5], NAME_EN: 'Karasu' },
    { kato: 395400000, coordinates: [63.6, 53.4], NAME_EN: 'Kostanay' },
    { kato: 395600000, coordinates: [64.2, 53.9], NAME_EN: 'Mendikara' },
    { kato: 395800000, coordinates: [64.0, 51.3], NAME_EN: 'Nauryzym' },
    { kato: 396200000, coordinates: [65.6, 53.4], NAME_EN: 'Sarykol' },
    { kato: 396600000, coordinates: [65.4, 54.1], NAME_EN: 'Uzynkol' },
    { kato: 396800000, coordinates: [62.9, 53.8], NAME_EN: 'Federov' },
    { kato: 391600000, coordinates: [66.8, 50.1], NAME_EN: 'Arkalyk c.a.' },
    { kato: 392400000, coordinates: [62.9, 52.9], NAME_EN: 'Rudnyi' },
  ]

const diagramTypes: DiagramType[] = [
  'population',
  // 'nationality',
  'employment',
  'education',
  'healthcare',
  'studying',
  'road',
  'harvest',
  'lifestock',
  'finance',
]

const options = [
  { label: 'GOOD', value: 'GOOD' },
  { label: 'SATISFACTORY', value: 'SATISFACTORY' },
  { label: 'BAD', value: 'BAD' },
]

const roadCoverOptions = [
  { label: 'PAVED', value: 'PAVED' },
  { label: 'GROUND', value: 'GROUND' },
  { label: 'GRAVEL', value: 'GRAVEL' },
]

const documentationStatusOptions = [
  { label: 'HAVE', value: 'HAVE' },
  { label: 'IN_PROCESS', value: 'IN_PROCESS' },
  { label: 'NOT_HAVE', value: 'NOT_HAVE' },
]

const educationOptions = [
  { label: 'school', value: 'true' },
  { label: 'kindergarten', value: 'false' },
]

const requirementsOptions = [
  'NOT_NEEDED',
  'MAJOR_RENOVATION',
  'CURRENT_RENOVATION',
  'RECONSTRUCTION'
]

const sportSectionsList: string[] = [
  "no",
  "basketball",
  "boxing",
  "volleyball",
  "freestyle-wrestling",
  "greco-roman-wrestling",
  "judo",
  "kazakh-wrestling",
  "karate",
  "athletics",
  "skiing",
  "ping-pong",
  "swimming",
  "taekwondo",
  "dancing",
  "9kumalak",
  "weight-lifting",
  "figure-skating",
  "football",
  "hockey",
  "gymnastics",
  "chess"
];

const sportSectionOptions = sportSectionsList.map(section => ({ label: section, value: section }));


export {
  kostanayDistricts,
  diagramTypes,
  options,
  roadCoverOptions,
  documentationStatusOptions,
  educationOptions,
  requirementsOptions,
  sportSectionsList,
  sportSectionOptions
}