import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getImageUrl } from '../right-menu/education.component';
import { formatDate } from '../../utils/helpers.utils';
import { DownloadIcon } from '../../assets/icons';

interface IProps {
  images: any[];
  openImageModal: (image: any, images: any, name: string) => void;
  name: string;
  isColumn?: boolean;
}

const ImageGrid: React.FC<IProps> = ({ images, openImageModal, name = 'Фото', isColumn = false }) => {
  const { t } = useTranslation();

  const [imagesObj, setImagesObj] = useState<any>({});

  useEffect(() => {
    if (!isColumn) {
      const keySet = Array.from(new Set(images.map((item: any) => item.fileType)));
      const newObj = keySet.reduce((result: any, key: string) => {
        const filteredImages = images.filter((item: any) => item.fileType === key);
        result[key] = filteredImages;
        return result;
      }, {});

      setImagesObj(newObj);
    } else {
      setImagesObj(images);
    }
  }, [images]);

  const handleDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'фото.png');
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <>
      {
        !isColumn
          ? imagesObj && Object.keys(imagesObj) && Object.keys(imagesObj).length > 0 && Object.keys(imagesObj).map((key: string) => (
            <div className="block" style={{ border: 0, padding: 0 }} key={key}>
              <div>{t(key).split(' ')[0]} <b>{t(key).split(' ')[1]}</b></div>
              <div className="images">
                {imagesObj[key].map((image: any) =>
                  <>
                    {image.imageIds.map((imageId: number) =>
                      <div className="images__item">
                        <img
                          src={getImageUrl(imageId)}
                          onClick={() => openImageModal({ imageId, date: formatDate(image.date) }, imagesObj, name)}
                          alt="school"
                          key={`img_${imageId}`}
                        />
                        <div className="footer">
                          <button onClick={() => handleDownload(getImageUrl(imageId))}><DownloadIcon /></button>
                          <div className="images__date">{formatDate(image.date)}</div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))
          : <>
            {
              imagesObj && Object.keys(imagesObj) && Object.keys(imagesObj).length > 0 && Object.keys(imagesObj).map((key: string) => (
                <div className="block" style={{ border: 0, padding: 0 }} >
                  <div><b>{t(key === 'before' ? "IMAGE_BEFORE" : "IMAGE_AFTER").split(' ')[1]}</b></div>
                  <div className="images">
                    {imagesObj[key].map((image: any) =>
                      <>
                        <div className="images__item">
                          <img
                            src={getImageUrl(image.id)}
                            onClick={() => openImageModal({ imageId: image.id, date: formatDate(image.date) }, imagesObj, name)}
                            alt="school"
                            key={`img_${image.id}`}
                          />
                          <div className="footer">
                            <button onClick={() => handleDownload(getImageUrl(image.id))}><DownloadIcon /></button>
                            <div className="images__date">{formatDate(image.date)}</div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))
            }
          </>
      }
    </>
  )
}

export default ImageGrid