import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getImageUrl } from '../right-menu/education.component';


import { IPeople } from '../../interfaces/snp.interface';
import PhotoPlaceholder from '../../assets/images/photo-placeholder.png';
import { PassportContainer, Item, Photo, PersonInfo } from './index.styles'
import ZoomableImageModal from '../zoomable-image.component';
import { capitalize, formatDate, getLang, keepCapitalized } from '../../utils/helpers.utils';
import { Phone } from '../../assets/icons';

interface IProps {
  people: IPeople,
}

const Passport: React.FC<IProps> = memo(({ people }) => {
  const { t } = useTranslation()

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openImageModal = (image: any) => {
    setIsOpen(true);
    setSelectedImage(image);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  }

  return (
    <PassportContainer>
      {people && people.length > 0 && people.map((person, index) =>
        <React.Fragment key={`${person.fullNameRu}_${index}_${Math.random()}`}>
          <Item>
            <Photo src={(person.photoId && getImageUrl(+person.photoId)) || PhotoPlaceholder} alt='profile photo' onClick={() => person.photoId && openImageModal({ date: null, imageId: +person.photoId })} />
            <PersonInfo>
              <div className='group'>
                <div className='name'>{person.type === 'p' && !person[`fullName${getLang()}`] ? t('form.policeObj.no') : capitalize(person[`fullName${getLang()}`] as string)}</div>
                {!(person.type === 'p' && !person[`fullName${getLang()}`]) && <div className='position'>{keepCapitalized(person[`position${getLang()}`] as string)}</div>}
              </div>

              {
                !(person.type === 'p' && !person[`fullName${getLang()}`]) && <>
                  <div className='gradient-divider'></div>

                  <div className='group'>
                    <div className='position'>{t('people.worksFrom')}:
                      <span className='date'> {person.inPositionFrom && formatDate(person.inPositionFrom)}</span>
                    </div>

                    <div className="phone">
                      <Phone />
                      <a className='phone-number' href={`tel:${person.phoneNumber}`}>{person.phoneNumber}</a>
                    </div>
                  </div>
                </>
              }

            </PersonInfo>
          </Item>
          {index !== people.length - 1 && <div className='divider'></div>}
        </React.Fragment>
      )}

      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={[]}
        />
      )}

    </PassportContainer>
  )
});

export default Passport