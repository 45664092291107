import { createContext, useContext, useState } from "react";

export type SharedContextType = {
  user: any;
  setUser: (user: any) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  isRightMenuOpen: boolean;
  setIsRightMenuOpen: (isOpen: boolean) => void;
};

interface IProps {
  children: React.ReactNode;
}

const SharedContext = createContext<SharedContextType>({
  user: null,
  setUser: () => { },
  isModalOpen: false,
  setIsModalOpen: () => { },
  isRightMenuOpen: false,
  setIsRightMenuOpen: () => { },
});

export const SharedProvider: React.FC<IProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isRightMenuOpen, setIsRightMenuOpen] = useState<boolean>(false);

  return <SharedContext.Provider value={{ user, setUser, isModalOpen, setIsModalOpen, isRightMenuOpen, setIsRightMenuOpen }}>{children}</SharedContext.Provider>;
};

export const useSharedContext = (): SharedContextType => useContext(SharedContext);
