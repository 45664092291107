import axios from "axios"
import { api } from "../pages"

export const getDistrictData = (kato: number, year: number) => {
  return axios.get(`/api/public/district/list?kato=${kato}&year=${year}`).then(res => res.data.data)
}

export const createObject = (kato: number) => {
  return api.post(`/supervisor/district/create?kato=${kato}`).then(res => res.data)
}

export const updateObject = (dto: any) => {
  return api.post(`/supervisor/district/update?kato`, { dto }).then(res => res.data)
}