import React, { useEffect, useState } from 'react'
import { Expand, ImageArrowLeft, ImageArrowRight, ImageDownload } from '../assets/icons';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ZoomableImageContainer } from './right-menu/index.styles';
import Modal from './modal-component';
import { getImageUrl } from './right-menu/education.component';
import { Text } from './text.component';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedImage: any;
  setSelectedImage: (imageId: number) => void;
  isFileUpload?: boolean;
  currentImageList: number[],
  objectName?: string
}

const ZoomableImageModal: React.FC<IProps> = ({ isOpen, onClose, isFileUpload, selectedImage, setSelectedImage, currentImageList, objectName = 'Фото' }) => {
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleNextImage = () => {
    if (selectedImage) {
      if (currentIndex < currentImageList.length - 1) {
        setCurrentIndex(currentImageList.indexOf(selectedImage.imageId));
        setSelectedImage({ ...selectedImage, imageId: currentImageList[currentIndex + 1] });
        setCurrentIndex((prev) => prev + 1)
      }
    }
  }

  const handlePrevImage = () => {
    if (selectedImage) {
      setCurrentIndex(currentImageList.indexOf(selectedImage.imageId));
      if (currentIndex > 0) {
        setSelectedImage({ ...selectedImage, imageId: currentImageList[currentIndex - 1] });
        setCurrentIndex((prev) => prev - 1)
      }
    }
  }

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = getImageUrl(+selectedImage.imageId);
    link.setAttribute('download', `${objectName}-${currentIndex}.png`);
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  useEffect(() => {
    if (!selectedImage) return;

    if (currentImageList.indexOf(selectedImage.imageId) !== currentIndex) {
      setCurrentIndex(currentImageList.indexOf(selectedImage.imageId))
    }

    document.addEventListener('keydown', (e) => {
      if (e.code === 'ArrowRight') {
        handleNextImage()
      }

      if (e.code === 'ArrowLeft') {
        handlePrevImage()
      }
    })
  }, [selectedImage])

  return (
    <Modal isFileUpload={isFileUpload} isOpen={isOpen} onClose={onClose} hasBg={false}>
      <ZoomableImageContainer>
        <ImageArrowLeft className={'arrow prev'} onClick={handlePrevImage} />
        <div className="modal-body">
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <div className="tools">
                  <button className='zoom' onClick={() => zoomIn()}>+</button>
                  <button className='zoom' onClick={() => zoomOut()}>-</button>
                  <button className='zoom' onClick={() => resetTransform()}><Expand /> </button>
                </div>
                {selectedImage && <TransformComponent>
                  <img src={getImageUrl(+selectedImage.imageId)} alt="object" />
                </TransformComponent>}
              </>
            )}
          </TransformWrapper>
          <div className="footer">
            <Text fontSize='0.875rem' color="#BFBFBF">{selectedImage.date}</Text>
            <div className='object-info'>
              <Text fontSize='0.875rem' color="#BFBFBF">{objectName}</Text>
              {currentImageList.length > 0 && <Text fontSize='0.875rem' color="#BFBFBF" style={{ whiteSpace: 'nowrap' }} >{currentIndex + 1} / {currentImageList.length}</Text>}
            </div>
            <div className='download' onClick={handleDownload}>
              <ImageDownload />
              <Text color='#92D050' fontSize='0.875rem' style={{ textDecoration: 'underline' }}>{t('download')}</Text>
            </div>
          </div>
        </div>
        <ImageArrowRight className={'arrow next'} onClick={handleNextImage} />
      </ZoomableImageContainer>
    </Modal>

  )
}

export default ZoomableImageModal