import React, { useEffect, useState } from 'react'
import FloatingTabs from './floatin-tabs.component';
import Header from './header.component';
import Content from './content.component';

import { RightMenuContainer } from './index.styles';
import { IMarker, IRegion } from '../../interfaces/snp.interface';
import { navLinks } from '../../constants/navlinks.constant';
import { useParams, useNavigate } from 'react-router-dom';
import { useSharedContext } from '../../contexts/shared.context';

interface IProps {
  selectedLink: string | null;
  setSelectedLink: (link: string | null) => void;
  selectedMarker: IMarker | null;
  selectedRegion: IRegion | undefined;
}

const RightMenu: React.FC<IProps> = ({ selectedLink, setSelectedLink, selectedMarker, selectedRegion }) => {
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const navigate = useNavigate();
  const { link, kato, tab } = useParams()
  const { isRightMenuOpen, setIsRightMenuOpen } = useSharedContext()

  const closeModal = () => {
    setIsRightMenuOpen(false);
  }

  useEffect(() => {
    if (tab && link && link === 'infrastructure') {
      setSelectedTab(tab)
      setIsRightMenuOpen(true)
    } else if (navLinks.map(item => item.name).includes(kato as any) && link) {
      setSelectedTab(link)
      setIsRightMenuOpen(true)
    } else if ((kato && navLinks.map(item => item.name).includes(kato as any)) || (link && navLinks.map(item => item.name).includes(link as any))) {
      setIsRightMenuOpen(true)
    } else if (kato && ['analytics', 'akim', 'info'].includes(kato as string)) {
      setSelectedTab(kato)
      setIsRightMenuOpen(true)
    } else if (link && ['analytics', 'akim', 'info'].includes(link as string)) {
      setSelectedTab(link)
      setIsRightMenuOpen(true)
    }

  }, [link, kato, tab])

  useEffect(() => {
    if (selectedTab) {
      setIsRightMenuOpen(true)
    }
  }, [selectedTab])

  return (
    <>
      {isRightMenuOpen
        ? <RightMenuContainer>
          <Header selectedTab={selectedTab} setSelectedTab={setSelectedTab} onClose={closeModal} />
          <Content
            selectedTab={selectedTab}
            selectedLink={selectedLink}
            selectedMarker={selectedMarker}
            setSelectedTab={setSelectedTab}
            selectedRegion={selectedRegion}
          />
        </RightMenuContainer>
        : <FloatingTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      }
    </>
  )
}

export default RightMenu