import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { sections } from '../../constants/navlinks.constant';

import Logo from '../../assets/images/Logo.svg';
import LogoRu from '../../assets/images/Logo-ru.svg';

import { LeftMenuWrapper, LogoContainer, Sections } from './admin-page.styles';
import { t } from '../../utils/helpers.utils'
import { getStatistics } from '../../requests/kato.request';

const LeftMenu = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const [statistics, setStatistics] = React.useState<any>(null);
  const handleLogout = () => {
    navigate('/')
  }

  useEffect(() => {
    getStatistics()
      .then((res) => {
        setStatistics(res[390000000]);
      })
  }, [])

  return (
    <LeftMenuWrapper>
      <LogoContainer src={language === 'kz' ? Logo : LogoRu} alt="Logo" onClick={handleLogout} />
      <Sections>
        {sections.map((section) =>
          <NavLink
            to={`/admin/${section}`}
            className={({ isActive }) => `${isActive ? 'active' : ''}`}
            key={section}
          >
            {t(section, language)}
          </NavLink>
        )}

        <NavLink to='/' onClick={handleLogout}>
          {t('logout', language)}
        </NavLink>

        {<div className="add-info">
          <a href="https://adilet.zan.kz/rus/docs/V17N0007014" target='_blank' rel="noreferrer">{t('RegionImprovementRules', language)}</a>
        </div>}

        <div className="statistics">
          <span className='item'>{t('totalVillages', language)}: {statistics?.total}</span>
          <span className='item'>{t('inProcessVillages', language)}: {statistics?.inProcess}</span>
          <span className='item'>{t('completedVillages', language)}: {statistics?.completed}</span>
          <span className='item'>{t('requiredVillages', language)}: {(statistics?.required)}</span>
          <span className='item'>{t('activePercentage', language)}: {isNaN(statistics?.completionPercent) ? 0 : `${(statistics?.completionPercent * 100).toFixed(2)}`}%</span>
        </div>
      </Sections>
    </LeftMenuWrapper>
  )
}

export default LeftMenu