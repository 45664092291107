import React, { useEffect } from 'react'
import { IMarker, IRegion } from '../../interfaces/snp.interface'

import { CurrentSnpWrapper, Image, SnpInfo } from './index.styles'
import { DefaultGerb, ProdBackground, TestBackground } from '../../assets/images'
import { useTranslation } from 'react-i18next';
import { getLang } from '../../utils/helpers.utils';
import {
  gerb391600000,
  gerb392400000,
  gerb393200000,
  gerb393400000,
  gerb393600000,
  gerb394000000,
  gerb394200000,
  gerb394400000,
  gerb394800000,
  gerb395000000,
  gerb395200000,
  gerb395400000,
  gerb395600000,
  gerb395800000,
  gerb396200000,
  gerb396400000,
  gerb396600000,
  gerb396800000
} from '../../assets/images/gerbs/gerbs'

interface IProps {
  selectedRegion: IRegion;
  selectedSnp: IMarker;
}

const CurrentSnp: React.FC<IProps> = ({ selectedRegion, selectedSnp }) => {
  const { t } = useTranslation();
  const [bgImage, setBgImage] = React.useState<string>('')
  const [gerb, setGerb] = React.useState<string>(DefaultGerb);

  const getGerb = () => {
    switch (selectedRegion?.kato) {
      case 391600000:
        return gerb391600000;
      case 392400000:
        return gerb392400000;
      case 393200000:
        return gerb393200000;
      case 393400000:
        return gerb393400000;
      case 393600000:
        return gerb393600000;
      case 394000000:
        return gerb394000000;
      case 394200000:
        return gerb394200000;
      case 394400000:
        return gerb394400000;
      case 394800000:
        return gerb394800000;
      case 395000000:
        return gerb395000000;
      case 395200000:
        return gerb395200000;
      case 395400000:
        return gerb395400000;
      case 395600000:
        return gerb395600000;
      case 395800000:
        return gerb395800000;
      case 396200000:
        return gerb396200000;
      case 396400000:
        return gerb396400000;
      case 396600000:
        return gerb396600000;
      case 396800000:
        return gerb396800000;
      default:
        return DefaultGerb
    }
  }

  useEffect(() => {
    const hostname = window.location.host;
    const bg = hostname.includes('abat-kost.kz') ? ProdBackground : TestBackground;
    setBgImage(bg);
  }, []);

  useEffect(() => {
    if (selectedRegion) {
      setGerb(getGerb);
    } else {
      setGerb(DefaultGerb);
    }
  }, [selectedRegion]);

  return (
    <CurrentSnpWrapper bgImage={bgImage}>
      <Image src={gerb} noShadow />
      <SnpInfo>
        <div className="name">{selectedSnp?.[`name${getLang()}`] || t('welcome')}</div>
        <div className="region">{selectedRegion?.[`name${getLang()}`] || t('choose-village')}</div>
      </SnpInfo>
    </CurrentSnpWrapper>
  )
}

export default CurrentSnp