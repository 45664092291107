import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Routes from './pages';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak/keycloak";


import './index.scss';
import './i18n';
import { SharedProvider } from './contexts/shared.context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <BrowserRouter>
      <SharedProvider>
        <Routes />
      </SharedProvider>
    </BrowserRouter>
  </ReactKeycloakProvider>

);


