import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { checkVal, getLang } from '../../utils/helpers.utils';
import { IProject } from '../../interfaces/snp.interface';
import { ToastContainer, toast } from 'react-toastify';
import { createObject, updateObject } from '../../requests/supervisor.request';
import { getProject } from '../../requests/snp.request';
import { FormGroup, Wrapper } from './admin-page.styles';
import { Form, Field, Formik } from 'formik';
import FormFooter from '../insfrastructureProjects/footer.component';
import ImageGrid from '../insfrastructureProjects/image-grid.component';
import { getChildren } from '../../requests/kato.request';
import { t } from '../../utils/helpers.utils'
import { eventTypes } from '../../constants/region.constant';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import { ITempVal } from '../insfrastructureProjects/education-form.component';

const initialFormData = {
  totalSum: 0,
  additionalFields: {}
}

const skipList = [
  'id',
  'kato',
  'imageCode',
  'files',
  'additionalFields',
]

const errMsg: any = {
  nameRu: 'objectName',
  nameKz: 'objectName',
  workStart: 'deadlines',
  workEnd: 'deadlines',
}

const FloodSituationForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();
  const wrapperRef = useRef<any>(null);

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [districtList, setDistrictList] = useState<any[]>([]);
  const [snpList, setSnpList] = useState<any[]>([]);
  const [tempVal, setTempVal] = useState<ITempVal>({
    isAdding: false,
    labelKz: '',
    labelRu: '',
    valueKz: '',
    valueRu: '',
    isAddingText: false,
    textKz: '',
    textRu: '',
    labelError: false,
    valueError: false,
    textError: false
  });

  const [dates, setDates] = useState<any>({
    workStart: null,
    workEnd: null
  });

  const handleValueChange = (key: string, val: any, setFieldValue: (fieldName: string, value: string) => void) => {
    if (key === 'workStart' || key === 'workEnd') {
      if (val.split('-')[0].length > 4) return;
      setDates((prev: any) => ({ ...prev, [key]: val }))
      setFieldValue(key, val);
      setFormData((prev: any) => ({ ...prev, [key]: val }));
    } else {
      setFieldValue(key, val);
      setFormData({ ...formData, [key]: val });
    }
  }

  const handleSave = (values: IProject) => {
    setErrors({})

    const keys = Object.keys(values).filter((key: string) => !skipList.includes(key));
    for (const key of keys) {
      if (!checkVal(values[key])) {
        setErrors((prev: any) => ({ ...prev, [errMsg[key] || key]: true }))
        toast.error(t(`errors.${errMsg[key] || key}`, language))
        return
      }
    }

    let data: any = {
      ...values,
    }

    const add_keys = Object.keys(data.additionalFields || {}).filter((key: string) => !['ru', 'kz'].includes(key))

    for (const key of add_keys) {
      if (key.includes('no-label')) {
        if (!checkVal(data.additionalFields[key].textKz) || !checkVal(data.additionalFields[key].textRu)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      } else {
        if (!checkVal(data.additionalFields[key].valueKz) || !checkVal(data.additionalFields[key].valueRu)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      }
    }

    updateObject('FLOOD_SITUATION', data)
      .then(res => toast.success(t(`toast.save_success`, language)))
      .catch(err => toast.error(t(`toast.save_error`, language)))
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('FLOOD_SITUATION', objectId).then(res => {
      setFormData(res.infrastructureEntity)
      setImages(res.images)
      kato && getChildren(+kato).then(response => {
        setDistrictList(response)
      })
      if (res.infrastructureEntity.districtKato){
        getChildren(res.infrastructureEntity.districtKato).then(response => {
          setSnpList(response)
        })
      }
    })
  }

  const loadForm = useCallback(() => {
    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId);
    } else {
      kato && createObject('FLOOD_SITUATION', +kato).then(res => {
        navigate(`/admin/${kato}/flood_situation/${res.id}`)
      })
    }
  }, [navigate, objectId, kato])

  const handleDistrictChange = (kato: number) => {
    getChildren(kato).then(res => {
      setSnpList(res);
      setFormData({ ...formData, snpKato: null })
    })
  }

  const addText = () => {
    if (!checkVal(tempVal.textRu) || !checkVal(tempVal.textKz)) {
      setTempVal((prev: any) => ({ ...prev, textError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const field = {
      ...formData.additionalFields,
      [`no-label-${+new Date()}`]: { textKz: tempVal.textKz, textRu: tempVal.textRu }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })

    setTempVal({ ...tempVal, textRu: '', textKz: '', isAddingText: false, textError: false })
    return field
  }

  const deleteField = (key: string) => {
    const { [key]: deleted, ...rest } = formData.additionalFields;
    setFormData({ ...formData, additionalFields: rest })
    setTempVal({ ...tempVal, isAdding: false, labelError: false, valueError: false })
    updateObject('SNP_IMPROVEMENT', { ...formData, additionalFields: rest })
      .then(() => loadForm())
  }

  const addField = () => {
    if (!checkVal(tempVal.labelKz) || !checkVal(tempVal.labelRu)) {
      setTempVal((prev: any) => ({ ...prev, labelError: true }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempVal.valueKz) || !checkVal(tempVal.valueRu)) {
      setTempVal((prev: any) => ({ ...prev, valueError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const field = {
      ...formData.additionalFields,
      [+new Date()]: {
        labelKz: tempVal.labelKz,
        labelRu: tempVal.labelRu,
        valueKz: tempVal.valueKz,
        valueRu: tempVal.valueRu,
      }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })
    setTempVal({ ...tempVal, labelKz: '', valueKz: '', isAdding: false, labelRu: '', valueRu: '', labelError: false, valueError: false })
    return field
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormGroup>
        <label className='required' htmlFor={`districtKato`}>{t(`form.district-name`, lang)}</label>
        <Field
          id={`districtKato`}
          name={`districtKato`}
          placeholder={t(`form.district-name`, lang)}
          onChange={(e: any) => {
            setFieldValue(`districtKato`, e.target.value)
            handleDistrictChange(+e.target.value)
          }}
          className={errors['districtKato'] ? 'error' : ''}
          as='select'
          defaultValue={formData.districtKato || ""}
        >
          <option value="" hidden></option>
          {districtList.map((item) => <option key={item.kato} value={item.kato}>{item[`name${lang}`]}</option>)}
        </Field>
      </FormGroup>
      <FormGroup>
        <label className='required' htmlFor={`snpKato`}>{t(`form.snp.name`, lang)}</label>
        <Field
          id={`snpKato`}
          name={`snpKato`}
          placeholder={t(`form.snp.name`, lang)}
          onChange={(e: any) => setFieldValue(`snpKato`, e.target.value)}
          className={errors['snpKato'] ? 'error' : ''}
          as='select'
          defaultValue={formData.snpKato || ""}
        >
          <option value="" hidden></option>
          {snpList.map((item) => <option key={item.kato} value={item.kato}>{item[`name${lang}`]}</option>)}
        </Field>
      </FormGroup>
      <FormGroup>
        <label className='required' htmlFor={`name${lang}`}>{t(`snp_improvement.name`, lang)}</label>
        <Field
          id={`name${lang}`}
          name={`name${lang}`}
          placeholder={t(`snp_improvement.name`, lang)}
          className={errors[`objectName`] ? 'error' : ''}
        />
      </FormGroup>

      <FormGroup>
        <label className='required' htmlFor={`eventType`}>{t(`district-improvement-form.eventType`, lang)}</label>
        <Field
          id={`eventType`}
          name={`eventType`}
          placeholder={t(`district-improvement-form.eventType`, lang)}
          onChange={(e: any) => setFieldValue(`eventType`, e.target.value)}
          className={errors['eventType'] ? 'error' : ''}
          as='select'
          defaultValue={formData.eventType || ""}
        >
          <option value="" hidden></option>
          {eventTypes.map((item) => <option key={item} value={item}>{t(`eventType.${item}`, lang)}</option>)}
        </Field>
      </FormGroup>

      <div className="row sb wrap">
        <FormGroup>
          <label className="required" htmlFor={`workStart`}>{t(`form.workStart`, lang)}</label>
          <Field
            id={`workStart`}
            name={`workStart`}
            placeholder={t(`form.workStart`, lang)}
            as="input"
            type="date"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('workStart', e.target.value, setFieldValue)}
            max={dates.workEnd || formData.workEnd?.split('T')[0] || null}
            className={errors['date'] ? 'error' : ''}
            defaultValue={formData.workStart?.split('T')[0] || null}
            value={dates.workStart || formData.workStart?.split('T')[0] || null}
          />
        </FormGroup>
      </div>
      <div className="row sb wrap">
        <FormGroup>
          <label className="required" htmlFor={`workEnd`}>{t(`form.workEnd`, lang)}</label>
          <Field
            id={`workEnd`}
            name={`workEnd`}
            placeholder={t(`form.workEnd`, lang)}
            as="input"
            type="date"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('workEnd', e.target.value, setFieldValue)}
            max={'2030-12-31'}
            className={errors['date'] ? 'error' : ''}
            defaultValue={formData.workEnd?.split('T')[0] || null}
            value={dates.workEnd || formData.workEnd?.split('T')[0] || null}
            min={dates.workStart || formData.workStart?.split('T')[0] || null}
          />
        </FormGroup>
      </div>

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        onSave={handleSave}
        setTempVal={setTempVal}
        errors={errors}
        tempVal={tempVal}
        onAdd={addField}
        onTextAdd={addText}
        onDelete={deleteField}
        type='flood_situation'
      />
    </>
  }

  return (
    <Wrapper ref={wrapperRef}>
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="grid-item">
                  {renderFields(getLang(), setFieldValue)}
                </div>
                <div className="grid-item">
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                </div>
                <FormFooter type='FLOOD_SITUATION' formId={formData.id} parent={wrapperRef?.current} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />

      {objectId !== 'new' && <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'FLOOD_SITUATION'}
        images={images}
        lang={language}
      />}
    </Wrapper >
  )
}

export default FloodSituationForm